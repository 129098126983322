import React, { useEffect, useState, useRef } from 'react';
import { FaGlobe, FaChartLine, FaCogs } from 'react-icons/fa';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/components/Release.module.css';
import animationStyles from '../styles/Animation.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Release: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1 });

  const cards = [
    {
      title: 'Sonner',
      subtitle: '統合型ビジネス管理アプリケーション',
      image: '/images/image_04.png',
      icon: FaChartLine,
      description: '一人社長やフリーランス向けの統合型ビジネス管理アプリケーションを提供しています。経理、営業、顧客管理などの機能を一つのプラットフォームに集約し、効率的な業務運営をサポートします。直感的なインターフェースと高度な自動化機能により、管理業務の負担を大幅に軽減し、ユーザーがコア業務に集中できる環境を実現します。'
    },
    {
      title: 'OHKINI',
      subtitle: '海外観光客向けガイドアプリケーション',
      image: '/images/image_03.png',
      icon: FaGlobe,
      description: '日本を訪れる外国人観光客と地元ガイドをつなぐ革新的なプラットフォームです。ユーザーは大阪、京都、神戸などの関西地域の隠れた魅力を、地元の人々の案内で体験できます。文化交流を促進し、より深い日本体験を提供することで、インバウンド観光の質を高めています。'
    },
    {
      title: '知識図書館',
      subtitle: '美容学生向け国試対策サービス',
      image: '/images/image_05.png',
      icon: FaCogs,
      description: '美容学生と開発された美容学生向けの国試対策サービスです。国家試験の準備を効率化し、学生が就職活動に専念できる環境を提供します。これにより、学生は試験対策と就職活動の両立が可能となり、業界へのスムーズな移行をサポートします。さらに、ヘアサロンとの連携を強化し、新人美容師の定着率向上を目指しています。'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div ref={ref} className={`${styles.release} ${isVisible ? animationStyles.visible : ''}`}>
      <div className={`${commonStyles.subPageView} ${styles.view}`}>
        <h1 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>
          たった一つのアプリで、世界が変わる。
        </h1>
        <p className={`${commonStyles.subtitle} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>
          Change the world, with just one app.
        </p>
        <div className={styles.descriptionWrapper}>
          <p className={`${commonStyles.description} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            当社では、個人のユーザー向けにも自社のアプリを開発して多くのソリューションを提供しています。
            現代社会の課題に対して、根本的解決を目標として常に新しいプロジェクトが発足しています。
            プロジェクトごとに少数精鋭のチームをつくり、試行錯誤を重ねてユーザーにより良いソリューションを提供できるようなアプリを開発しています。
          </p>
        </div>

        <div className={commonStyles.section}>
          <div className={`${styles.cardContainerWrapper} ${animationStyles.fadeInRight} ${isVisible ? animationStyles.visible : ''}`}>
            <div
              className={styles.cardContainer}
              style={{ transform: `translateX(-${activeIndex * (100 / cards.length)}%)` }}
            >
              {cards.map((card, index) => (
                <div key={index} className={styles.card}>
                  <div className={styles.imageWrapper}>
                    <img src={card.image} alt={card.title} className={styles.image} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        
          <div className={`${styles.dotContainer} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            {cards.map((_, index) => (
              <button
                key={index}
                className={`${styles.dot} ${index === activeIndex ? styles.activeDot : ''}`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
          <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <span>03 — RELEASE</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Release;