/// <reference path="../../types/recaptcha.d.ts" />
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/pages/Contact.module.css';

const inquiryTypeMap: { [key: string]: string } = {
  'estimate': 'お見積もり',
  'consultation': 'ご相談',
  'support': 'サポート',
  'recruit': '採用応募',
  'other': 'その他'
};

const jobTypeMap: { [key: string]: string } = {
  'frontendEngineer': 'フロントエンドエンジニア',
  'backendEngineer': 'バックエンドエンジニア',
  'aiEngineer': 'AIエンジニア',
  'designer': 'デザイナー',
  'otherJob': 'その他'
};

const Contact: React.FC = () => {
  const [inquiryType, setInquiryType] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [department, setDepartment] = useState('');
  const [jobType, setJobType] = useState('');
  const [message, setMessage] = useState('');
  const [messagePlaceholder, setMessagePlaceholder] = useState('');
  const [submitted, setSubmitted] = useState<{ status: boolean; type: string }>({ status: false, type: '' });
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const handleRecaptchaBadge = () => {
      const badge = document.querySelector('.grecaptcha-badge');
      if (badge instanceof HTMLElement) {
        badge.style.position = 'static';
        badge.style.marginBottom = '20px';
        badge.classList.add('visible');
      }
    };

    const loadRecaptcha = () => {
      if (typeof window.grecaptcha === 'undefined') {
        setTimeout(loadRecaptcha, 100);
        return;
      }

      window.grecaptcha.ready(() => {
        if (!document.querySelector('.g-recaptcha-response')) {
          window.grecaptcha.render('recaptcha-container', {
            sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
            size: 'invisible'
          });
          handleRecaptchaBadge();
        }
      });
    };

    loadRecaptcha();

    // コンポーネントのアンマウント時にクリーンアップ
    return () => {
      const badge = document.querySelector('.grecaptcha-badge');
      if (badge instanceof HTMLElement) {
        badge.style.position = '';
        badge.style.marginBottom = '';
      }
    };
  }, []);

  useEffect(() => {
    if (inquiryType) {
      switch (inquiryType) {
        case 'estimate':
          setMessagePlaceholder('どのようなサービスをご希望ですか？（例：サービス開発/アプリ開発/ホームページ制作/その他のお見積もり）');
          break;
        case 'consultation':
          setMessagePlaceholder('どのようなことでお困りですか？（例：料金について/サービスについて/その他のご相談）');
          break;
        case 'support':
          setMessagePlaceholder('どのようなサポートが必要ですか？（例：使い方/新機能の追加/不具合報告/その他のサポート）');
          break;
        case 'recruit':
          setMessagePlaceholder(`○フロントエンド
            実務経験：3年
            言語：HTML/CSS/JavaScript/TypeScript
            フレームワーク：React/Next.js

○バックエンド
            実務経験：1年
            言語：PHP/Laravel/MySQL
            サーバー：AWS/Azure

○デザイナー
            実務経験：5年
            習得言語：Adobe Photoshop/Adobe Illustrator`);
          break;
        case 'other':
        default:
          setMessagePlaceholder('お問い合わせの内容をご記入ください。');
          break;
      }
    }
  }, [inquiryType]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const captchaResponse = await window.grecaptcha.execute();
      
      if (!captchaResponse) {
        alert('reCAPTCHAの検証に失敗しました。もう一度お試しください。');
        return;
      }

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      if (!apiBaseUrl) {
        throw new Error('API_BASE_URLが設定されていません。');
      }

      const response = await fetch(`${apiBaseUrl}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name, 
          email, 
          company, 
          department, 
          inquiryType: inquiryTypeMap[inquiryType], 
          jobType: inquiryType === 'recruit' ? jobTypeMap[jobType] : null,
          message, 
          captchaResponse 
        }),
      });

      const responseText = await response.text();
      let responseData;
      
      try {
        responseData = JSON.parse(responseText);
      } catch (parseError) {
        console.error('サーバーレスポンス:', responseText);
        throw new Error('サーバーからの応答が不正です。');
      }

      if (!response.ok) {
        throw new Error(responseData.error || 'サーバーエラーが発生しました。');
      }

      setSubmitted({ status: true, type: inquiryType });

    } catch (error) {
      console.error('エラー詳細:', error);
      alert((error as Error).message || '送信中にエラーが発生しました。もう一度お試しください。');
    } finally {
      setIsLoading(false);
    }
  };

  if (submitted.status) {
    return (
      <div className={commonStyles.subPageView}>
        <section className={commonStyles.section}>
          <h4 className={commonStyles.slogan}>お問い合わせフォーム</h4>
          <p className={commonStyles.subPageDescription}>
            {submitted.type === 'recruit' ? (
              <>
                採用応募ありがとうございます。<br />
                内容を確認の上、担当者より1週間以内にご連絡させていただきます。<br />
              </>
            ) : (
              <>
                お問い合わせありがとうございます。<br />
                内容を確認の上、担当者より3営業日以内にご連絡させていただきます。<br />
              </>
            )}
          </p>
          <div className={styles.homeButtonContainer}>
            <Link to="/" className={styles.homeButton}>ホームに戻る</Link>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className={commonStyles.subPageView} id="contact">
      <section className={commonStyles.section}>
        <h4 className={commonStyles.slogan}>お問い合わせフォーム</h4>
        <p className={commonStyles.subPageDescription}>
          下記のフォームより、お問い合わせ内容をご入力ください。<br />
          担当者より、ご入力いただいたメールアドレス宛にご連絡いたします。
        </p>
      </section>

      <section className={`${commonStyles.section} ${styles.section}`}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit} className={styles.form}>

          <div className={styles.formGroup}>
              <label htmlFor="inquiryType">お問い合わせ種別</label>
              <select
                id="inquiryType"
                value={inquiryType}
                onChange={(e) => setInquiryType(e.target.value)}
                required
              >
                <option value="">選択してください</option>
                <option value="estimate">お見積もり</option>
                <option value="consultation">ご相談</option>
                <option value="support">サポート</option>
                <option value="recruit">採用応募</option>
                <option value="other">その他</option>
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="name">お名前</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='例：田中 太郎'
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">メールアドレス</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='例：t-yamada@example.com'
                required
              />
            </div>

            {inquiryType !== 'recruit' && (
              <>
                <div className={styles.formGroup}>
                  <label htmlFor="company">会社名<span className={styles.optional}>任意</span></label>
                  <input
                    type="text"
                    id="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder='例：maverick株式会社'
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="department">部署名<span className={styles.optional}>任意</span></label>
                  <input
                    type="text"
                    id="department"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    placeholder='例：営業部'
                  />
                </div>
              </>
            )}

            {inquiryType === 'recruit' && (
              <>
                <div className={styles.formGroup}>
                  <label htmlFor="jobType">希望職種</label>
                  <select
                    id="jobType"
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                    required
                  >
                <option value="">選択してください</option>
                <option value="frontendEngineer">フロントエンドエンジニア</option>
                <option value="backendEngineer">バックエンドエンジニア</option>
                <option value="aiEngineer">AIエンジニア</option>
                <option value="designer">デザイナー</option>
                <option value="otherJob">その他</option>
              </select>
            </div>
              </>
            )}
            <div className={styles.formGroup}>
              <label htmlFor="message">{inquiryType !== 'recruit' ? 'お問い合わせ内容' : '経歴等'}</label>
              <textarea
                id="message"
                value={message}
                style={inquiryType !== 'recruit' ? { height: '300px' } : {height: '500px'}}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={messagePlaceholder || 'お問い合わせの内容をご記入ください。'}
                required
              ></textarea>
            </div>

            
            <button type="submit" className={styles.submitButton} disabled={isLoading}>
              {isLoading ? '送信中...' : '送信'}
            </button>
            <div className={styles.recaptchaContainer}>
              <div id="recaptcha-container" className={styles.recaptcha}></div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;