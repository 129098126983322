import React from 'react';
import styles from '../styles/pages/ServiceDetail.module.css';

const AIDevelopment: React.FC = () => {
  return (
    <div className={styles.serviceDetail}>
      <h1 className={styles.mainTitle}>AIサービス開発</h1>
      <p className={styles.subtitle}>最新のAI技術を活用したソリューション</p>

      <section className={styles.section}>
        <h2>サービス概要</h2>
        <p>
          maverickのAIサービス開発は、最先端の人工知能技術を活用し、
          お客様のビジネスに革新的なソリューションを提供します。
          機械学習、深層学習、自然言語処理など、様々なAI技術を駆使して、
          業務効率化や新しい価値の創造を実現します。
        </p>
      </section>

      <section className={styles.section}>
        <h2>主な提供サービス</h2>
        <ul>
          <li>AIを活用した業務自動化システムの開発</li>
          <li>機械学習モデルの設計と実装</li>
          <li>自然言語処理を用いたチャットボットの開発</li>
          <li>画像認識システムの構築</li>
          <li>予測分析ソリューションの提供</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>導入メリット</h2>
        <ul>
          <li>業務効率の大幅な向上</li>
          <li>人的ミスの削減</li>
          <li>データに基づいた意思決定の実現</li>
          <li>新しいビジネスモデルの創出</li>
          <li>競争力の強化</li>
        </ul>
      </section>
    </div>
  );
};

export default AIDevelopment;