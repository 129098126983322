import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/components/ParallaxImage.module.css';

const ParallaxImage: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [offset, setOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const handleScroll = () => {
      if (containerRef.current) {
        const { top } = containerRef.current.getBoundingClientRect();
        setOffset(window.innerHeight - top - 100);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  return (
    <div ref={containerRef} className={styles.parallaxContainer}>
      <div
        className={styles.parallaxImage}
        style={{ transform: `translateY(${isVisible ? offset * 0.3 : 0}px)` }}
      />
    </div>
  );
};

export default ParallaxImage;