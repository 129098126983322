import React, { useEffect, useRef } from 'react';
import { interpolate } from 'd3-interpolate';
import { select } from 'd3-selection';
import 'd3-transition';
import styles from '../styles/components/LogoAnimation.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const LogoAnimation: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(containerRef, { threshold: 0.1 });

  const svgRef = useRef<SVGSVGElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const particlesRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!isVisible || !svgRef.current || !containerRef.current || !textRef.current || !particlesRef.current) return;

    const svg = select(svgRef.current);
    const container = containerRef.current;
    const text = textRef.current;
    const particles = particlesRef.current;
    const ctx = particles.getContext('2d');
    if (!ctx) return;

    const width = container.clientWidth;
    const height = container.clientHeight;

    // SVGパスの定義
    const paths = [
      "M200,300 C200,400 300,400 300,300 C300,200 400,200 400,300 C400,400 300,400 300,300 C300,200 200,200 200,300 Z", // 無限大マーク
      "M183.12,442 L316.88,92.26 L450.65,442 L316.88,442 L183.12,92.26 L49.35,442 Z" // 会社のロゴ
    ];

    const path = svg.append("path")
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-width", 10)
      .attr("d", paths[0]) // 最初のパスを設定
      .attr("opacity", 1); // 初期状態で非表示

    let currentPathIndex = 0;

    // パスの変形処理
    function morphPath() {
      if (currentPathIndex < paths.length - 1) {
        const nextPathIndex = currentPathIndex + 1;
        const interpolator = interpolate(paths[currentPathIndex], paths[nextPathIndex]);
    
        path.transition()
          .duration(2000)
          .attrTween("d", () => (t: number) => {
            const interpolatedPath = interpolator(t);
            return interpolatedPath.replace(/NaN/g, '0');
          })
          .on("end", () => {
            currentPathIndex = nextPathIndex;
          });
      }
    }

    function fadeInPath() {
      path.transition()
        .duration(1000)
        .attr("opacity", 1) // フェードイン
        .on("end", morphPath); // フェードイン後に形を変える
    }

    if (isVisible) {
      fadeInPath(); // フェードイン開始
    }

    // パーティクルの設定と描画
    const particleArray: Particle[] = [];
    const mouse = { x: 0, y: 0, radius: 100 };

    class Particle {
      x: number;
      y: number;
      size: number;
      baseX: number;
      baseY: number;
      density: number;

      constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
        this.size = Math.random() * 5 + 1;
        this.baseX = x;
        this.baseY = y;
        this.density = (Math.random() * 30) + 1;
      }

      draw(ctx: CanvasRenderingContext2D) {
        ctx.fillStyle = 'rgba(255,255,255,0.8)';
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      }

      update() {
        const dx = mouse.x - this.x;
        const dy = mouse.y - this.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const forceDirectionX = dx / distance;
        const forceDirectionY = dy / distance;
        const maxDistance = mouse.radius;
        const force = (maxDistance - distance) / maxDistance;
        const directionX = forceDirectionX * force * this.density;
        const directionY = forceDirectionY * force * this.density;

        if (distance < mouse.radius) {
          this.x -= directionX;
          this.y -= directionY;
        } else {
          if (this.x !== this.baseX) {
            const dx = this.x - this.baseX;
            this.x -= dx / 10;
          }
          if (this.y !== this.baseY) {
            const dy = this.y - this.baseY;
            this.y -= dy / 10;
          }
        }
      }
    }

    function initParticles() {
      particleArray.length = 0;
      const totalPoints = 200;
      for (let i = 0; i < totalPoints; i++) {
        const x = Math.random() * width;
        const y = Math.random() * height;
        particleArray.push(new Particle(x, y));
      }
    }

    function animateParticles() {
      if (!ctx) return;
      ctx.clearRect(0, 0, width, height);
      for (let i = 0; i < particleArray.length; i++) {
        particleArray[i].update();
        particleArray[i].draw(ctx);
      }
      requestAnimationFrame(animateParticles);
    }

    function handleResize() {
      particles.width = container.clientWidth;
      particles.height = container.clientHeight;
      initParticles();
    }

    function handleMouseMove(event: MouseEvent) {
      mouse.x = event.x;
      mouse.y = event.y;
    }

    function handleScroll() {
      const { top, height } = container.getBoundingClientRect();
      const scrollPercentage = Math.max(0, Math.min(1, 1 - (top / height)));

      // 背景色のグラデーション
      const startColor = [246, 50, 104]; // ピンク
      const endColor = [91, 72, 242]; // メインカラー
      const interpolatedColor = startColor.map((start, i) => 
        Math.round(start + (endColor[i] - start) * scrollPercentage)
      );
      container.style.backgroundColor = `rgb(${interpolatedColor.join(',')})`;

      // ロゴとテキストのアニメーション
      transformLogo(scrollPercentage);
    }

    function transformLogo(scrollPercentage: number) {
      const delay = 0.9; // 遅延の程度（0.0から1.0の間）
      const transformY = scrollPercentage * 100 * delay;
      
      if (svgRef.current) {
        svgRef.current.style.transform = `translateY(${transformY}px)`;
      }
      
      if (textRef.current) {
        textRef.current.style.transform = `translateY(${transformY}px)`;
        if (scrollPercentage < 1) {
          textRef.current.style.opacity = (1 - scrollPercentage).toString();
        } else {
          textRef.current.style.opacity = '1';
        }
      }
    }

    morphPath();
    handleResize();
    initParticles();
    animateParticles();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    particles.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      particles.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isVisible]);

  return (
    <div ref={containerRef} className={styles.container}>
      {isVisible && (
        <>
          <canvas ref={particlesRef} className={styles.particles}></canvas>
          <svg 
            ref={svgRef} 
            viewBox="0 0 500 500" 
            xmlns="http://www.w3.org/2000/svg" 
            className={styles.logo} 
            width="500" 
            height="500" 
            style={{ 
              filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2))',
              transform: 'translateY(50px)',
              transition: 'transform 0.3s ease-out'
            }}
          ></svg>
          <div 
            ref={textRef} 
            className={styles.text} 
            style={{ 
              opacity: 0, 
              transform: 'translateY(50px)', 
              textShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.3s ease-out, opacity 0.3s ease-out'
            }}
          >
            <h2>maverick inc.</h2>
            <p>Break the mold</p>
          </div>
        </>
      )}
    </div>
  );
};

export default LogoAnimation;