import React, { useState, useEffect, useRef } from 'react';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/components/News.module.css';
import animationStyles from '../styles/Animation.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { useWindowSize } from '../hooks/useWindowSize';

interface NewsItem {
  id: number;
  title: string;
  content: string;
  date: string;
  image: string;
}

const News: React.FC = () => {
  const [showAll, setShowAll] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1 });
  const width = useWindowSize().width;

  const getDisplayCount = () => {
    if (width <= 1000) return 2;
    return 3;
  };

  const getGridColumns = () => {
    if (width <= 1000) return 2;
    return 3;
  };

  const newsItems: NewsItem[] = [
    {
      id: 1,
      title: 'Webサイトリニューアルしました。',
      content: '最新のAI技術を活用した革新的なサービスの開発をスタートしました。',
      date: '2024-11-01',
      image: '/images/news/news_02.jpg'
    },
    {
      id: 2,
      title: 'QUINTBRIDGEピッチイベントに登壇させていただきました。',
      content: '最新のAI技術を活用した革新的なサービスの開発をスタートしました。',
      date: '2024-10-21',
      image: '/images/news/news_01.JPG'
    },
    {
      id: 3,
      title: 'ハイブリット型マッチングアプリ「PEANUTS」プロジェクト始動',
      content: '大手企業様とのDX推進プロジェクトが始動しました。',
      date: '2024-10-01',
      image: '/images/news/news_05.png'
    },
    {
      id: 4,
      title: '「AI基礎」セミナーのお知らせ',
      content: '最新のWeb技術に関するセミナーを開催いたします。',
      date: '2024-04-10',
      image: '/images/news/news_03.png'
    },
    {
      id: 5,
      title: '海外観光客向けローカルガイドマッチングアプリ「Ohkini」リリース',
      content: '海外観光客向けローカルガイドマッチングアプリ「Ohkini」をリリースしました。',
      date: '2024-01-01',
      image: '/images/news/news_04.jpg'
    },
    {
      id: 6,
      title: 'オフィス移転のお知らせ',
      content: '事業拡大に伴い、新しいオフィスに移転することになりました。',
      date: '2023-05-30',
      image: '/images/image_01.jpg'
    },
  ];

  const displayedNews = showAll ? newsItems : newsItems.slice(0, getDisplayCount());

  return (
    <div ref={ref} className={`${commonStyles.view} ${isVisible ? animationStyles.visible : ''}`}>
      <h1 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>最新のニュース</h1>
      <p className={`${commonStyles.subtitle} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>Stay updated with our journey.</p>

      <div className={commonStyles.section}>
        <div
          className={`${styles.newsGrid} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}
          style={{
            gridTemplateColumns: `repeat(${getGridColumns()}, 1fr)`,
          }}
        >
          {displayedNews.map((item, index) => (
            <div
              key={item.id}
              className={`${styles.newsCard} ${animationStyles.fadeIn}`}
              style={{
                transitionDelay: `${0.1 * (index + 1)}s`
              }}
            >
              <div className={styles.imageWrapper}>
                <img src={item.image} alt={item.title} className={styles.image} />
              </div>
              <div className={styles.newsContent}>
                <span className={styles.newsDate}>{item.date}</span>
                <h3 className={styles.newsTitle}>{item.title}</h3>
              </div>
            </div>
          ))}
        </div>

        {newsItems.length > getDisplayCount() && !showAll && (
          <div className={styles.moreButtonWrapper}>
            <button className={styles.moreButton} onClick={() => setShowAll(true)}>
              <span>もっと見る</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="m6 9 6 6 6-6" />
              </svg>
            </button>
          </div>
        )}

        <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <span>05 — NEWS</span>
        </div>
      </div>
    </div>
  );
};

export default News;