import { useEffect, useState, RefObject } from 'react';

// IntersectionObserverInitを拡張したオプションインターフェース
interface IntersectionOptions extends IntersectionObserverInit {
  freezeOnceVisible?: boolean; // 一度表示されたら監視を停止するオプション
}

const useIntersectionObserver = (
  ref: RefObject<HTMLElement>,
  {
    threshold = 0.3, // デフォルトの閾値：要素の30%が表示されたときに反応
    root = null, // デフォルトのroot：ビューポート全体
    rootMargin = '-20%', // デフォルトのrootMargin：要素が画面の下から20%の位置に来たときに反応
    freezeOnceVisible = true, // デフォルトをtrueに変更
  }: IntersectionOptions = {}
): boolean => {
  // 要素が表示されているかどうかの状態を管理
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect(() => {
    // Intersection Observerの作成
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (isElementIntersecting || isIntersecting) {
          setIsIntersecting(true);
          if (freezeOnceVisible) {
            observer.unobserve(entry.target);
          }
        }
      },
      { threshold, root, rootMargin }
    );

    // 参照された要素が存在する場合、監視を開始
    if (ref.current) {
      observer.observe(ref.current);
    }

    // クリーンアップ関数：コンポーネントのアンマウント時に監視を停止
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, threshold, root, rootMargin, freezeOnceVisible, isIntersecting]);

  // 要素が表示されているかどうかの状態を返す
  return isIntersecting;
};

export default useIntersectionObserver;