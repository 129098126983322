import React, { useRef } from 'react';
import commonStyles from '../styles/App.module.css';
import animationStyles from '../styles/Animation.module.css';
import styles from '../styles/pages/Service.module.css';
import { FaLightbulb, FaMobileAlt, FaCogs, FaCode, FaBullhorn, FaChartLine } from 'react-icons/fa';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Service: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1 });

  const services = [
    {
      title: 'DXソリューション',
      description: 'クライアントの課題を解決するためのサービスやアプリケーションの開発を行います。',
      details: [
        { icon: <FaCode />, name: 'AIサービス開発' },
        { icon: <FaLightbulb />, name: 'Webサービス開発' },
        { icon: <FaMobileAlt />, name: 'モバイルアプリ開発' },
        { icon: <FaCogs />, name: 'ソフトウェア開発' },
      ],
      link: '/services/dx-solutions'
    },
    {
      title: 'アプリケーション開発',
      description: '一般ユーザー向けに独自の価値を提供する自社アプリケーションを開発します。',
      details: [
        { icon: <FaLightbulb />, name: '消費者向けアプリ' },
        { icon: <FaMobileAlt />, name: 'ライフスタイルアプリ' },
        { icon: <FaCode />, name: 'インタラクティブアプリ' },
        { icon: <FaCogs />, name: '業務アプリ' },
      ],
      link: '/services/app-development'
    },
    {
      title: 'デジタルコンサルティング',
      description: '新商品やサービスのマーケティング、データ活用のサポートを提供するトータルサポート事業です。',
      details: [
        { icon: <FaBullhorn />, name: 'WEB・SNSマーケティング' },
        { icon: <FaChartLine />, name: 'ビッグデータ活用支援' },
        { icon: <FaLightbulb />, name: 'ビジュアルデザイン作成' },
        { icon: <FaCogs />, name: 'デジタル戦略サポート' },
      ],
      link: '/services/digital-consulting'
    },
  ];

  return (
    <div ref={ref} className={`${commonStyles.subPageView} ${isVisible ? animationStyles.visible : ''}`} id="service">
      <section className={commonStyles.section}>
        <h4 className={`${commonStyles.slogan} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>革新的に変わる。企業も。ユーザーも。</h4>
        <p className={`${commonStyles.subPageDescription} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          私たちは、クライアントと共にデジタルの未来を創り上げています。
          AI技術を活用したサービス開発から、モバイルアプリやWebサービスの提供、そしてデータを活かしたマーケティングまで、
          多岐にわたるソリューションでビジネスの成長を加速させます。革新を追求し、企業の課題解決に最適なアプローチを提供し続けています。
        </p>
      </section>

      <section className={commonStyles.section}>
        <div className={`${styles.serviceGrid} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          {services.map((service, index) => (
            <div 
              key={index} 
              className={`${styles.serviceCard} ${animationStyles.fadeInTop}`}
              style={{transitionDelay: `${0.1 * (index + 1)}s`}}
            >
              <h3 className={styles.serviceTitle}>{service.title}</h3>
              <p className={styles.serviceDescription}>{service.description}</p>
              <div className={styles.serviceDetails}>
                {service.details.map((detail, detailIndex) => (
                  <div key={detailIndex} className={styles.detailItem}>
                    <span className={styles.detailIcon}>{detail.icon}</span>
                    <span className={styles.detailName}>{detail.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        
        <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <span>01 — SERVICE</span>
        </div>
      </section>     
    </div>
  );
};

export default Service;