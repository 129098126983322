import React from 'react';
import commonStyles from '../styles/App.module.css';
import '../styles/pages/Privacy.module.css';

const Privacy: React.FC = () => {
  return (
    <div className={commonStyles.subPageView} id="privacy">
      <section className={commonStyles.section}>
        <h4 className={commonStyles.slogan}>プライバシーポリシー</h4>
      </section>

      <div>
        <h3>1. 個人情報の取り扱いについて</h3>
        <p>maverick株式会社（以下、「当社」）は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下、「個人情報保護法」）を遵守すると共に、以下のプライバシーポリシー（以下、「本ポリシー」）に従って、お客様の個人情報を適切に取り扱います。</p>
      </div>

      <div>
        <h3>2. 個人情報の収集方法</h3>
        <p>当社は、お客様から直接提供いただく方法、Webサイトの利用状況から収集する方法等により、個人情報を取得します。</p>
      </div>

      <div>
        <h3>3. 個人情報の利用目的</h3>
        <p>当社は、取得した個人情報を以下の目的で利用します：</p>
        <ul>
          <li>当社サービスの提供、維持、保護及び改善のため</li>
          <li>お客様からのお問い合わせ等への対応のため</li>
          <li>当社の新サービス、またはイベント等のご案内のため</li>
          <li>当社サービスに関する規約等の変更などを通知するため</li>
        </ul>
      </div>

      <div>
        <h3>4. 個人情報の第三者提供</h3>
        <p>当社は、お客様の同意を得ることなく、個人情報を第三者に提供することはありません。ただし、以下の場合は除きます：</p>
        <ul>
          <li>法令に基づく場合</li>
          <li>人の生命、身体または財産の保護のために必要がある場合</li>
          <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合</li>
          <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合</li>
        </ul>
      </div>

      <div>
        <h3>5. 個人情報の安全管理</h3>
        <p>当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、適切な安全管理措置を講じます。</p>
      </div>

      <div>
        <h3>6. プライバシーポリシーの変更</h3>
        <p>当社は、必要に応じて、本ポリシーを変更することがあります。変更後のプライバシーポリシーは、当社Webサイトに掲載した時点から効力を生じるものとします。</p>
      </div>

      <div>
        <h3>7. お問い合わせ</h3>
        <p>本ポリシーに関するお問い合わせは、以下の連絡先までお願いいたします。</p>
        <p>
          maverick株式会社<br />
          〒534-0023 大阪府大阪市都島区都島南通2-6-14<br />
          メール：info@maverick-japan.com
        </p>
      </div>
        
    </div>
  );
};

export default Privacy;