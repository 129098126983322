import React, { useEffect, useRef } from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import styles from '../styles/components/ScrollText.module.css';

const ScrollText: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const width = useWindowSize().width;

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && textRef.current) {
        const { top, height } = containerRef.current.getBoundingClientRect();
        const scrollPercentage = (window.innerHeight - top) / (window.innerHeight + height);
        
        let scrollSpeed = 1;
        if (width <= 1200) {
          scrollSpeed = 0.8;
        }
        if (width <= 780) {
          scrollSpeed = 0.6;
        }
        
        const translateX = -scrollPercentage * 100 * scrollSpeed;
        textRef.current.style.transform = `translateX(${translateX}%)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [width]);

  return (
    <div ref={containerRef} className={styles.scrollTextContainer}>
      <div ref={textRef} className={styles.scrollText}>
        Break the mold. Embrace perspectives from every angle. Find the best practice.
        Break the mold. Embrace perspectives from every angle. Find the best practice.
        Break the mold. Embrace perspectives from every angle. Find the best practice.
      </div>
    </div>
  );
};

export default ScrollText;