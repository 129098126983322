import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import AIDevelopment from './pages/AIDevelopment';
import Company from './pages/Company';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import LogoAnimation from './components/LogoAnimation';
import Navigation from './components/Navigation';
import News from './components/News';
import ParallaxImage from './components/ParallaxImage';
import Privacy from './pages/Privacy';
import Recruit from './pages/Recruit';
import Release from './components/Release';
import ScrollText from './components/ScrollText';
import Services from './components/Services';
import Service from './pages/Service';
import Work from './components/Work';
import styles from './styles/App.module.css';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.app}>
      <ScrollToTop />
      <Navigation />
      <main className={styles.main}>
        <Routes>
          <Route path="/" element={
            <>
              <Home />
              <ParallaxImage/>
              <AboutUs/>
              <LogoAnimation />
              <ScrollText />
              <Services />
              <ParallaxImage/>
              <Release />
              <Work />
              <News />
            </>
          } />
          <Route path="/company" element={<Company />} />
          <Route path="/services" element={<Service />} />
          <Route path="/services/ai-development" element={<AIDevelopment />} />
          <Route path="/recruit" element={<Recruit />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
