import React, { useState, useEffect } from 'react';
import styles from '../styles/components/Home.module.css';

const Home: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // animatedTextの表示後にscrollIndicatorWrapperを表示
    const timer = setTimeout(() => {
      setShowScrollIndicator(true);
    }, 2000); // アニメーションの合計時間（1s + 1s）後に表示

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={styles.homeContainer}>
      <div className={styles.overlay}>
        <div>
          <h1 className={styles.mainTitle}>
            <span className={styles.animatedText}>Break the mold.</span>
          </h1>
          <p className={styles.subTitle}>
            <span className={styles.animatedText}>革新的かつ、オリジナルなDX化を。</span>
          </p>
        </div>
      </div>
      <div className={`${styles.scrollIndicatorWrapper} ${isScrolled ? styles.fadeOut : ''} ${showScrollIndicator ? styles.fadeIn : ''}`}>
        <span className={styles.scrollText}>scroll</span>
        <div className={styles.scrollIndicator}></div>
      </div>
    </div>
  );
};

export default Home;