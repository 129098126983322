import React, { useRef, useEffect, useState } from 'react';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/pages/Company.module.css';
import animationStyles from '../styles/Animation.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Company: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isIntersecting = useIntersectionObserver(ref, { threshold: 0.1 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      setIsVisible(true);
    }
  }, [isIntersecting]);

  const philosophyItems = [
    {
      title: 'ビジョン',
      subtitle: 'The future we build together.',
      description: '固定観念を打ち破り、革新的なデジタルソリューションを通じて、クライアントと共に新たな未来を創造することを使命としています。私たちは、常識にとらわれない斬新なアプローチで、クライアントの可能性を最大限に引き出し、ビジネスの進化を加速させます。'
    },
    {
      title: 'ミッション',
      subtitle: 'What drives us forward.',
      description: '最新のテクノロジーとクリエイティブなソリューションを駆使して、クライアントのビジネス課題を解決し、持続可能な成長を支援することを使命としています。私たちは、柔軟かつ革新的なアプローチで、単なる解決策以上の価値を提供し、クライアントのビジョンを共に実現します。'
    },
    {
      title: 'コアバリュー',
      subtitle: 'The principles we live by.',
      description: '私たちは、創造性、自律性、そして献身的なサポートを核心的な価値観としています。型破りな発想を歓迎し、各個人の独創性を尊重します。同時に、クライアントの成功に全力を尽くし、感動を与えるソリューションを提供することを誓約します。'
    },
    {
      title: 'ゴール',
      subtitle: 'The goal we strive for.',
      description: '私たちの目標は、クライアントと共に成長し、社会に革新をもたらすことです。固定観念に縛られない発想と高度な技術力で、クライアントのビジネスを新たな高みへと導きます。同時に、社員一人一人が自己実現を果たし、創造性豊かな組織として、より良い未来の創造に貢献することを目指します。'
    }
  ];

  return (
    <div ref={ref} className={`${commonStyles.subPageView} ${isVisible ? animationStyles.visible : ''}`} id="company">
      <div>
        <section className={commonStyles.section}>
          <h3 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>企業理念</h3>
          <h4 className={`${commonStyles.slogan} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>Break the mold.</h4>
          <p className={`${commonStyles.subPageDescription} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>常識に囚われない革新的なアイディアと高い技術力で、最大の価値を創出します。</p>
        </section>
      
        <section className={commonStyles.section}>
          <div className={`${styles.philosophyGrid} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            {philosophyItems.map((item, index) => (
              <div 
                key={index} 
                className={`${styles.philosophyItem} ${animationStyles.fadeIn}`}
                style={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
                  transition: 'opacity 0.5s ease-out, transform 0.5s ease-out'
                }}
              >
                <h4>{item.title}</h4>
                <p className={commonStyles.subtitle}>{item.subtitle}</p>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <span>01 — PHILOSOPHY</span>
          </div>
        </section>
      </div>

      <div>
        <h3 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>会社情報</h3>
        <section className={`${commonStyles.section} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <table className={styles.infoTable}>
            <tbody>
              <tr>
                <th>社名</th>
                <td>maverick株式会社</td>
              </tr>
              <tr>
                <th>設立</th>
                <td>2022年8月30日</td>
              </tr>
              <tr>
                <th>代表取締役</th>
                <td>窪山 裕樹</td>
              </tr>
              <tr>
                <th>事業内容</th>
                <td>
                  <ul>
                    <li>AIサービス開発事業</li>
                    <li>Webサービス開発事業</li>
                    <li>モバイルアプリ開発事業</li>
                    <li>ソフトウェア開発事業</li>
                    <li>WEB/SNSマーケティング事業</li>
                    <li>デジタルコンサルティング事業</li>
                    <li>地域活性化事業</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>主な取引先（五十音順、敬称略）</th>
                <td>
                  <ul>
                    <li>O2LABO企画</li>
                    <li>株式会社武芸シザー</li>
                    <li>CLASSIX株式会社</li>
                    <li>クローバー引越しセンター</li>
                    <li>SNOWBOARD PROSHOP EMW</li>
                    <li>だいらテック</li>
                    <li>日本電気事株式会社</li>
                    <li>FUJI工業株式会社</li>
                    <li>MariageMaya</li>
                    <li>六甲八幡神社</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>顧問</th>
                <td>
                  <ul>
                    <li>税理士法人ベンチャーサポート</li>
                    <li>弁護士法人ベンチャーサポート法律事務所</li>
                    <li>ベンチャーサポート行政書士法人</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <span>02 — INFORMATION</span>
          </div>
        </section>
      </div>

      <div>
        <h3 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>沿革</h3>
        <section className={`${commonStyles.section} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <table className={styles.timelineTable}>
            <tbody>
              <tr>
                <th>2022年8月</th>
                <td>maverick株式会社設立</td>
              </tr>
              <tr>
                <th>2022年9月</th>
                <td>DXソリューション事業を開始</td>
              </tr>
              <tr>
                <th>2023年12月</th>
                <td>アプリケーション開発事業を開始</td>
              </tr>
              <tr>
                <th>2024年2月</th>
                <td>デジタルコンサルティング事業を開始</td>
              </tr>
            </tbody>
          </table>
          <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <span>03 — HISTORY</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Company;