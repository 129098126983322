import React, { useRef, useEffect, useState } from 'react';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/components/Work.module.css';
import animationStyles from '../styles/Animation.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Work: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const projectsWrapperRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1, freezeOnceVisible: false });
  const isProjectsVisible = useIntersectionObserver(projectsWrapperRef, { threshold: 0.1, freezeOnceVisible: false });

  const projects = [
    {
      title: 'CLASSIX株式会社様',
      image: '/images/image_01.jpg',
      category: ['アプリケーション開発'],
    },
    {
      title: '株式会社武芸シザー様',
      image: '/images/work/work_02.png',
      category: ['ブランディングサイト', 'オウンドメディア制作'],
    },
    {
      title: 'MariageMaya様',
      image: '/images/work/work_03.png',
      category: ['ブランディングサイト', 'SNS/Webマーケティング'],
    },
    {
      title: '02LABO企画様',
      image: '/images/work/work_07.png',
      category: ['オウンドメディア制作'],
    },
    {
      title: '日本電気工事株式会社様',
      image: '/images/image_01.jpg',
      category: ['ランディングページ制作', 'SNS/Webマーケティング'],
    },
    {
      title: 'FUJI工業株式会社様',
      image: '/images/image_01.jpg',
      category: ['コーポレートデザイン制作'],
    },
    {
      title: '株式会社プロネット様',
      image: '/images/image_01.jpg',
      category: ['アプリケーション開発'],
    },

    {
      title: '六甲八幡神社様',
      image: '/images/work/work_06.png',
      category: ['オウンドメディア制作'],
    },
  ];

  useEffect(() => {
    let animationFrameId: number | null = null;
    let startTime: number | null = null;
    let scrollWidth = 0;

    const scroll = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      if (projectsWrapperRef.current) {
        const scrollAmount = (progress / 50) % scrollWidth;
        projectsWrapperRef.current.style.transform = `translateX(-${scrollAmount}px)`;

        if (scrollAmount >= scrollWidth / 2) {
          projectsWrapperRef.current.style.transform = `translateX(0px)`;
          startTime = timestamp;
        }
      }

      if (isProjectsVisible) {
        animationFrameId = requestAnimationFrame(scroll);
      }
    };

    if (isProjectsVisible) {
      if (projectsWrapperRef.current) {
        scrollWidth = projectsWrapperRef.current.scrollWidth;
      }
      animationFrameId = requestAnimationFrame(scroll);
    } else {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
      }
      if (projectsWrapperRef.current) {
        projectsWrapperRef.current.style.transform = 'translateX(0)';
      }
    }

    return () => {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isProjectsVisible]);

  return (
    <div ref={ref} className={`${commonStyles.view} ${isVisible ? animationStyles.visible : ''}`}>
      <h1 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>私たちの実績</h1>
      <p className={`${commonStyles.subtitle} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>
        Our Achievements
      </p>
      <div className={commonStyles.section}>
        <p className={`${commonStyles.description} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          私たちは、Web/モバイルアプリケーション開発やコーポレートサイト制作、ブランディングサイトの制作など、様々な分野で実績を積み重ねてきました。
          また、SNSマーケティングやデジタル戦略のサポートも行い、クライアントのブランド価値を最大限に引き出すお手伝いをしています。
        </p>

        <div className={`${styles.projectsWrapperContainer} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <div ref={projectsWrapperRef} className={styles.projectsWrapper}>
            {[...projects, ...projects].map((project, index) => (
              <div
                key={index}
                className={`${styles.projectCard} ${animationStyles.fadeIn}`}
                style={{ transitionDelay: `${0.1 * (index + 1)}s` }}
              >
                <div className={styles.imageWrapper}>
                  <img src={project.image} alt={project.title} className={styles.image} />
                </div>
                <div className={styles.textWrapper}>
                  <p>{project.title}</p>
                  <ul className={styles.categoryList}>
                    {project.category.map((item, index) => (
                      <li key={index} className={styles.categoryItem}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <span>04 — WORKS</span>
        </div>
      </div>
    </div>
  );
};

export default Work;