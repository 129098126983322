import React, { useRef } from 'react';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/components/Services.module.css';
import animationStyles from '../styles/Animation.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Services: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1 });

  return (
    <div ref={ref} className={`${commonStyles.view} ${isVisible ? animationStyles.visible : ''}`} id="services">
      <h1 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>未来を創る、それが私たちの使命。</h1>
      <p className={`${commonStyles.subtitle} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>Shaping the future—that's our purpose.</p>
      
      <section className={commonStyles.section}>
        <div className={styles.content}>
          <p className={`${commonStyles.description} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            私たちは、クライアントの抱える課題を解決し、ビジネスの未来を共に築くための強力なパートナーです。
            どんな時も新しい可能性を模索しながら、クライアントの成功に貢献することを信念としています。
            私たちが提供するのは、クライアントの描くビジョンを実現、加速させるための「戦略的なソリューション」です。
            <br /><br />
            また、一般ユーザー向けの革新的なアプリケーション開発にも注力し、
            日常生活やビジネスシーンに新たな価値をもたらす製品を生み出しています。
            B2B、B2Cの両面で、テクノロジーを通じて社会に貢献することを目指しています。
          </p>
          <div className={styles.grid}>
            {[
              {
                title: 'DXソリューション',
                content: '企業固有のニーズに合わせたデジタルトランスフォーメーションソリューションを開発します。クライアントの視点を大切にしながら、業務の効率化だけでなく、データ活用や柔軟な運用を提案し、未来の成長基盤を一緒に築きます。ベストパートナーとして寄り添い、持続的な成長をサポートします。'
              },
              {
                title: 'アプリケーション開発',
                content: '一般ユーザー向けに独自の価値を提供する自社アプリケーションを開発しています。消費者向けAIアプリ、ライフスタイルアプリ、Webインタラクティブサービスなど、多様なニーズに応える革新的なソリューションを提供します。ユーザーエクスペリエンスを重視し、直感的な操作性と魅力的なデザインで、日常生活やビジネスシーンに新たな価値をもたらします。'
              },
              {
                title: 'デジタルコンサルティング',
                content: 'クライアントが抱える複雑なデジタル課題を解決するための総合的なアプローチを提供します。AIを活用したデータ分析や、効果的なマーケティング戦略の立案など、ビジネスに必要なすべてのサポートを行います。クライアントの成長を加速し、新しいビジネスチャンスを創出することを目指しています。'
              }
            ].map((card, index) => (
              <div
                key={index}
                className={`${styles.card} ${animationStyles.fadeInRight}`}
                style={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? 'translateX(0)' : 'translateX(20px)',
                  transition: `opacity 0.5s ease-out ${0.2 * (index + 1)}s, transform 0.5s ease-out ${0.2 * (index + 1)}s`
                }}
              >
                <h3>{card.title}</h3>
                <p>{card.content}</p>
              </div>
            ))}
          </div>
        </div>
        
        <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <span>02 — SERVICES</span>
        </div>

      </section>
    </div>
  );
};

export default Services;