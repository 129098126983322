import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/components/Footer.module.css';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.logoSection}>
          <Link to="/" className={styles.logo}>
            <svg width="40" height="40" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
              <polygon
                fill="none"
                stroke="var(--white)"
                strokeWidth="40"
                strokeMiterlimit="10"
                points="183.12 442 316.88 92.26 450.65 442 316.88 442 183.12 92.26 49.35 442 183.12 442"
              />
            </svg>
            <span>maverick inc.</span>
          </Link>
        </div>
        <div className={styles.divider}/>
        <div className={styles.linksSection}>
          <div className={styles.linkColumn}>
            <Link to="/company">会社概要</Link>
            <Link to="/privacy">プライバシーポリシー</Link>
          </div>

          <div className={styles.linkColumn}>
            <Link to="/recruit">採用情報</Link>
          </div>
          <div className={styles.linkColumn}>
            <Link to="/contact">お問い合わせ</Link>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        <p>&copy; 2022 maverick株式会社. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;