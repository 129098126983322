import React, { useRef, useEffect, useState } from 'react';
import animationStyles from '../styles/Animation.module.css';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/pages/Recruit.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Recruit: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isIntersecting = useIntersectionObserver(ref, { threshold: 0.1 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      setIsVisible(true);
    }
  }, [isIntersecting]);

  const positions = [
    {
      title: 'フロントエンドエンジニア',
      description: 'React、Vue.js、Angularなどを使用したWebアプリケーション開発',
      requirements: ['JavaScript/TypeScriptの深い理解', 'モダンなフレームワークの経験', 'レスポンシブデザインの実装経験'],
    },
    {
      title: 'バックエンドエンジニア',
      description: 'Node.js、PHP、C#、Python、Goなどを使用したサーバーサイド開発',
      requirements: ['サーバーサイド言語の深い理解', 'データベース設計と最適化の経験', 'RESTful APIの設計と実装経験'],
    },
    {
      title: 'AIエンジニア',
      description: '機械学習モデルの開発と実装',
      requirements: ['機械学習アルゴリズムの深い理解', 'Python、TensorFlow、PyTorchの経験', 'データ分析と前処理の経験'],
    },
    {
      title: 'デザイナー',
      description: 'ユーザーインターフェースのデザインと実装',
      requirements: ['UI/UXの深い理解', 'デザインツールの経験', 'レスポンシブデザインの実装経験'],
    },
  ];

  const foreignerPosition = {
    title: 'Global Position（海外人材採用）',
    description: 'グローバルな視点でのアプリケーション/サービス開発・デザイン',
    requirements: [
      '- 日本語能力試験N3以上',
      '- エンジニアまたはデザイナーとしての実務経験',
      '- 日本での就労ビザ取得が可能な方',
      '（海外在住の方は要相談）',
      '- チームでの協働に必要なコミュニケーション能力'
    ],
    benefits: [
      'ビザ申請サポート',
      '日本語学習支援',
      '住宅手当（条件あり）',
      'リモートワーク可（条件あり）'
    ]
  };

  return (
    <div ref={ref} className={`${commonStyles.subPageView} ${isVisible ? animationStyles.visible : ''}`} id="recruit">
      <section className={commonStyles.section}>
        <h4 className={`${commonStyles.slogan} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>共に創ろう。共に挑戦しよう。</h4>
        <p className={`${commonStyles.subPageDescription} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          maverickは、革新的なデジタルソリューションを提供する企業です。
          私たちは常に新しい技術に挑戦し、クライアントのビジネスを成功に導くことを目指しています。
          チャレンジ精神旺盛で、向上心のある方々のご応募を心よりお待ちしています。
        </p>
      </section>

      <div>
        <h3 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>募集職種</h3>
        <section className={commonStyles.section}>
          <div className={`${styles.positionsGrid} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            {positions.map((position, index) => (
              <div 
                key={index} 
                className={`${styles.positionCard} ${animationStyles.fadeInTop}`}
                style={{transitionDelay: `${0.1 * (index + 1)}s`}}
              >
                <h4>{position.title}</h4>
                <p>{position.description}</p>
                <h5>必要スキル：</h5>
                <ul>
                  {position.requirements.map((req, i) => (
                    <li key={i}>{req}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className={`${styles.foreignerRecruitment} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <p className={styles.foreignerDescription}>
              maverick株式会社では、多様な視点とスキルを持つグローバル人材を積極的に募集しています。
              私たちは、異なる文化や経験を持つメンバーと共に、革新的なソリューションを生み出すことを目指しています。
            </p>
            <div className={styles.foreignerCard}>
              <h4>{foreignerPosition.title}</h4>
              <p>{foreignerPosition.description}</p>
              <div className={styles.requirementColumns}>
                <div>
                  <h5>応募要件：</h5>
                  <ul>
                    {foreignerPosition.requirements.map((req, i) => (
                      <li key={i}>{req}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h5>待遇：</h5>
                  <ul>
                    {foreignerPosition.benefits.map((benefit, i) => (
                      <li key={i}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <span>01 — RECRUIT</span>
          </div>
        </section>
      </div>
        
      <div>
        <h3 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>応募方法</h3>
        <section className={commonStyles.section}>
          <div className={`${styles.applyGrid} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <div className={`${styles.applyCard} ${animationStyles.fadeIn}`}>
              <p>以下の情報を記載の上、<a href="/contact">お問い合わせページ</a>からご連絡ください。</p>
              <ul>
                <li>氏名</li>
                <li>返信用メールアドレス</li>
                <li>希望職種</li>
                <li>簡単な経歴</li>
              </ul>
              
              <p>※ 返信用メールアドレス宛に採用担当者からご連絡いたします。</p>
            </div>
          </div>
          <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
            <span>02 — HOW TO APPLY</span>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Recruit;