import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/components/Navigation.module.css';
import { useWindowSize } from '../hooks/useWindowSize';

const Navigation: React.FC = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [scrollTimeout, setScrollTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuAnimating, setIsMenuAnimating] = useState(false);
  const width = useWindowSize().width;
  
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;

    // 上にスクロールした場合、すぐに表示
    if (prevScrollPos > currentScrollPos) {
      setVisible(true);
    } else {
      // 下にスクロールした場合、すぐに非表示
      setVisible(false);
    }

    // 既存のタイムアウトをクリア
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    // 新しいタイムアウトを設定
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 1000);

    setScrollTimeout(timeout);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos, scrollTimeout]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [handleScroll, scrollTimeout]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleScroll, scrollTimeout]);

  const toggleMenu = () => {
    setIsMenuAnimating(true);
    setIsMenuOpen(!isMenuOpen);
    setTimeout(() => setIsMenuAnimating(false), 1000); // アニメーション時間に合わせて調整
  };

  const handleLinkHome = () => {
    window.location.href = '/';
  };

  return (
    <>
      <nav className={`${styles.navigation} ${visible ? styles.visible : styles.hidden}`}>
        <div className={styles.logo} onClick={handleLinkHome}>
          <svg width="30" height="30" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
            <polygon
              fill="none"
              stroke="var(--main-color)"
              strokeWidth="50"
              strokeMiterlimit="10"
              points="183.12 442 316.88 92.26 450.65 442 316.88 442 183.12 92.26 49.35 442 183.12 442"
            />
          </svg>
          <p className={styles.logoText}>maverick</p>
        </div>
        {!isMobile && (
          <ul className={styles.menu}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/company">Company</Link></li>
            <li><Link to="/services">Service</Link></li>
            <li><Link to="/recruit">Recruit</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        )}
      </nav>
      {isMobile && (
        <>
          <button 
            className={`${styles.hamburgerButton} ${isMenuOpen ? styles.open : ''}`} 
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.open : ''} ${isMenuAnimating ? styles.animating : ''}`}>
            <div className={styles.menuBackground}></div>
            <ul>
              <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
              <li><Link to="/company" onClick={toggleMenu}>Company</Link></li>
              <li><Link to="/services" onClick={toggleMenu}>Service</Link></li>
              <li><Link to="/recruit" onClick={toggleMenu}>Recruit</Link></li>
              <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default Navigation;