import React, { useRef } from 'react';

import animationStyles from '../styles/Animation.module.css';
import commonStyles from '../styles/App.module.css';
import styles from '../styles/components/AboutUs.module.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const AboutUs: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1 });

  return (
    <div ref={ref} className={`${commonStyles.view} ${isVisible ? animationStyles.visible : ''}`} id="aboutUs">
      <h1 className={`${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>当たり前。それが正解？</h1>
      <p className={`${commonStyles.subtitle} ${animationStyles.fadeInLeft} ${isVisible ? animationStyles.visible : ''}`}>
        Of course. That's the right answer, isn't it?
      </p>
      <p className={`${commonStyles.description} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
        私たちは、これまでの「当たり前」に囚われず、常に新しい可能性を模索することを信念としています。
        新しいものが次々と登場し、より柔軟性を求められる今の時代、当たり前や常識は必ずしも正解ではありません。
        「基本」の姿勢を持ちながらも、あらゆるパターンを考慮してクライアントにとってのベストプラクティスを見つけ出します。
        私たちは、IT技術でクライアントと共に課題を乗り越えるプロフェッショナルチームです。
      </p>

      <div className={`${commonStyles.section} ${animationStyles.fadeIn} ${isVisible ? animationStyles.visible : ''}`}>
        <div className={`${commonStyles.verticalText} ${styles.verticalText} ${animationStyles.fadeInTop} ${isVisible ? animationStyles.visible : ''}`}>
          <span>01 — ABOUT US</span>
        </div>

        <div className={`${styles.imageWrapper} ${animationStyles.fadeInRight} ${isVisible ? animationStyles.visible : ''}`}>
          <img src="/images/about/about_01.jpg" alt="About Us" className={styles.image} />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;